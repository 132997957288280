import { useQuery } from "@apollo/client";
import React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { GET_DOCUMENT_DETAILS } from "../grapqhl/queries";
import { Loading } from "../components/ProgressIndicators";
import { Error500 } from "./errors_5xx";
import NormContents from "../components/document_details/NormContents";
import DocumentOverview from "../components/document_details/DocumentOverview";
import SdgRankings from "../components/document_details/SdgRankings";


function DocumentDetails() {
    const documentID = useMatch("/detalhes/:documentID").params
    const navigate = useNavigate()

    const { data, loading, error } = useQuery(GET_DOCUMENT_DETAILS, {
        variables: documentID,
    });
    if (loading) return <Loading />;
    if (error) return <Error500 />;

    const document = {
        title: data.getDocumentDetails.document.title,
        source: data.getDocumentDetails.document.source,
        date: data.getDocumentDetails.document.date,
        abstract: data.getDocumentDetails.document.abstract,
        type: data.getDocumentDetails.document.type,
        externalURL: data.getDocumentDetails.document.externalURL,
        keywords: data.getDocumentDetails.document.keywords,
        fullText: data.getDocumentDetails.document.fullText,
    }

    const sdgRankings = data.getDocumentDetails.sdgRankings;

    return (
        <div className="container">
            <section className="section">
                <button className="button is-primary" onClick={() => navigate(-1)}>Voltar</button>
                <hr></hr>

                <DocumentOverview document={document} />

                <div className="columns">
                    <div className="column is-one-third">
                        <SdgRankings sdgRankings={sdgRankings} />
                    </div>

                    <div className="column">
                        <NormContents contents={document.fullText} />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default DocumentDetails;

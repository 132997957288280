import React from "react";

function SearchResultList(searchResults) {
    const items = searchResults.results.search.results.map(
        (resultItem) =>
            <li className="card" key={resultItem.documentID}>
                <div className="card-content">
                    <div className="media">
                        <div className="media-left">
                            <h2 className="tag is-warning is-rounded is-medium">{resultItem.type}</h2>
                        </div>
                        <div className="media-content">
                            <h1 className="title is-5 ">{resultItem.title}</h1>
                            <h2 className="subtitle is-6">Origem: {resultItem.source}</h2>
                        </div>
                    </div>

                    <div className="content">
                        <p className="is-size-6">{resultItem.abstract}</p>
                    </div>

                    <div className="card-footer">
                        <p>
                            <a href={`/detalhes/${resultItem.documentID}`}>Ver detalhes</a> | <a target="_blank" rel="noreferrer" href={resultItem.externalURL}>Link externo</a>
                        </p>
                    </div>
                </div>
            </li>

    );

    return (
        <ul>
            {items}
        </ul>
    );
}

export default SearchResultList;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import App from './App'

const apiUrl = process.env.REACT_APP_NORMALIS_API_URL;
const cache = new InMemoryCache();
const client = new ApolloClient({
  uri: apiUrl,
  cache,
  connectToDevTools: false
});

if (process.env.NODE_ENV === "development") {
  console.log("Running in development mode - backend API at ", apiUrl)
}

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

import React from "react";
import { UPLOAD_STATUS_IDLE, UPLOAD_STATUS_ERROR, UPLOAD_STATUS_SENDING, UPLOAD_STATUS_SENT } from "../../pages/sdg_mapper_submission";
import { UploadingFile } from "../ProgressIndicators";

function UserDocumentFileUpload({ file, uploadStatus, uploadHandler, documentTitle, documentAuthor, documentAbstract, docSubmissionData }) {
    let styleModifier = ""
    let submitButtonIsEnabled = true

    let titlePlaceHolder = documentTitle
    let authorPlaceHolder = documentAuthor

    const minDocAbstractLength = 50
    const minTitleAuthorLength = 10

    switch (uploadStatus) {
        case UPLOAD_STATUS_IDLE:
            styleModifier = " is-danger"
            break;

        case UPLOAD_STATUS_SENDING:
            styleModifier = " is-warning"
            break;

        case UPLOAD_STATUS_SENT:
            styleModifier = "is-success"
            break

        default:
            console.log("nothing to see here", uploadStatus)
            break;
    }

    const isFileTypeValid = validateFileType(file)
    submitButtonIsEnabled = isFileTypeValid

    const maxFileSize = 1024 * 1024 * 15 // 15MB
    const isFileSizeValid = (maxFileSize >= file.size)
    if (!isFileSizeValid) {
        submitButtonIsEnabled = false
    }

    const isDocTitleValid = documentTitle.trim().length >= minTitleAuthorLength
    if (!isDocTitleValid) {
        submitButtonIsEnabled = false
        titlePlaceHolder = "- título -"
    }

    const isDocAuthorValid = documentAuthor.trim().length >= minTitleAuthorLength
    if (!isDocAuthorValid) {
        submitButtonIsEnabled = false
        authorPlaceHolder = "- autoria -"
    }

    const isDocAbstractValid = documentAbstract.trim().length >= minDocAbstractLength
    if (!isDocAbstractValid) {
        submitButtonIsEnabled = false
    }

    return (
        <div className="card">
            <div className="card-content">
                <div className="media">
                    <div className="media-left">
                        <figure className="image is-48x48">
                            <img src="./../img/sdg_mapper/report_icon.png" alt="" />
                        </figure>
                    </div>

                    <div>
                        <p className="title is-5">{titlePlaceHolder}</p>
                        <p className="subtitle is-6">{authorPlaceHolder}</p>
                    </div>
                </div>

                <div className="content">
                    {
                        !isDocTitleValid ?
                            <p><span className="tag is-danger">Título precisa ter pelo menos {minTitleAuthorLength} caracteres</span></p> : ""
                    }
                    {
                        !isDocAuthorValid ?
                            <p><span className="tag is-danger">Autoria precisa ter pelo menos {minTitleAuthorLength} caracteres</span></p> : ""
                    }
                    {
                        !isDocAbstractValid ?
                            <p><span className="tag is-danger">Resumo precisa ter pelo menos {minDocAbstractLength} caracteres</span></p> : ""
                    }
                    {
                        !isFileTypeValid ?
                            <p><span className="tag is-danger">Tipo de arquivo inválido</span></p> : ""
                    }
                    {
                        !isFileSizeValid ?
                            <p><span className="tag is-danger">Tamanho do arquivo ({(file.size / 1024 / 1024).toFixed(1)}MB) excede o limite (15MB)</span></p> : ""
                    }
                    {
                        isDocTitleValid && isDocAuthorValid && isDocAbstractValid && isFileSizeValid && isFileTypeValid && true ?
                            <div>
                                <p className="tag is-success">Tudo pronto para a submissão do documento</p>
                                <p className="help">Tamanho do arquivo: {file.size} bytes</p>
                            </div> :
                            ""
                    }
                    <hr />
                    <p>
                        O NormaLIS não guarda a cópia do arquivo em seu banco de dados, mas precisa manter o conteúdo textual
                        para realizar o mapeamento dos <em>Objetivos de Desenvolvimento Sustentável</em>.
                    </p>
                    <p>
                        O resultado do mapeamento só pode ser acessado com a URL fornecida ao clicar no botão <strong>Visualizar Relatório</strong>.
                        Será aberta uma nova aba no seu navegador.
                    </p>
                </div>

                {(() => {
                    switch (uploadStatus) {
                        case UPLOAD_STATUS_SENDING:
                            return <footer className="card-footer">
                                <div
                                    className={`card-footer-item ${styleModifier}`}>
                                    <UploadingFile />
                                </div>
                            </footer>

                        case UPLOAD_STATUS_SENT:
                            return <footer className="card-footer"> <button
                                className={`button card-footer-item is-info`}
                                onClick={
                                    () => window.open(`/relatorio_ods/?doc_id=${docSubmissionData.document_id}`, "_blank", "noreferrer")
                                }
                            >
                                Visualizar Relatório
                            </button>
                            </footer>


                        case UPLOAD_STATUS_IDLE:
                            return <footer className="card-footer"> <button
                                className={`button card-footer-item ${styleModifier}`}
                                disabled={!submitButtonIsEnabled}
                                onClick={uploadHandler}>
                                {uploadStatus}
                            </button>
                            </footer>

                        case UPLOAD_STATUS_ERROR:
                            return <footer className="card-footer has-text-centered">
                                <p className="notification is-danger card-footer-item">
                                    Não foi possível analisar o documento <br /> Selecione outro tipo de arquivo e tente novamente
                                </p>
                            </footer>

                        default:
                            console.log("this should not happen")
                    }
                })()}
            </div>
        </div >
    );
}

function validateFileType(file) {
    console.log("file type is ", file.type)
    switch (file.type) {
        case 'application/pdf':
        case 'text/plain':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.oasis.opendocument.text':
        case 'application/vnd.ms-excel':
        case 'application/msword':
        case 'application/vnd.oasis.opendocument.spreadsheet':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return true
        default:
            return false
    }
}

export default UserDocumentFileUpload

import React from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { USER_SUBMITED_SDG_MAPPING } from "../grapqhl/sdg_mapper";
import { ProcessingSdgReport } from "../components/ProgressIndicators";
import { Error500SdgMapping } from "./errors_5xx";
import { Bar } from "react-chartjs-2";
import { sdgColors } from "../helpers/helpers";

function SdgReport() {
    const [params] = useSearchParams()
    const documentId = params.get("doc_id")

    const { data, loading, error } = useQuery(
        USER_SUBMITED_SDG_MAPPING, {
        variables: { documentId }
    });
    if (loading) return <ProcessingSdgReport />;
    if (error) return <Error500SdgMapping />;

    const document = data.userSubmitedDocument.document
    const sdgEntries = data.userSubmitedDocument.sdgTargets

    return (
        <div className="container">
            <section className="section">
                <h1 className="title">Mapeamento de Objetivos do Desenvolvimento Sustentável</h1>
            </section>

            <section className="section">
                {sdgEntries ? <SdgReportHeader document={document} /> : ""}
                {sdgEntries ? <SdgReportChart sdgEntries={sdgEntries} /> : ""}
                {sdgEntries ? <SdgReportTable sdgEntries={sdgEntries} /> : ""}

                <hr />
                <div className="content has-text-centered is-small">
                    <p>
                        O NormaLIS não retém o arquivo submetido pelo usuário, mas precisa
                        guardar o conteúdo textual extraído para que a análise possa ser realizada.
                    </p>
                    <p>
                        O mapeamento dos ODS no documento submetido pelo usuário só pode ser acessado pela URL gerada. Em nenhuma
                        outra hipótese seu conteúdo será disponibilizado ao público em geral, mesmo mediante
                        pesquisa.
                    </p>
                </div>
            </section>

        </div>
    );
}

function SdgReportHeader({ document }) {
    return (
        <table className="table is-striped mx-auto">
            <thead>
                <tr>
                    <th className="is-size-4 has-text-centered" colSpan="2">Informações do documento</th>
                </tr>
            </thead>

            <tbody>
                <tr className="is-size-5">
                    <td className="has-text-right">Título</td>
                    <td className="has-text-weight-bold">{document.title}</td>
                </tr>
                <tr>
                    <td className="has-text-right">Autoria</td>
                    <td className="has-text-weight-bold">{document.source}</td>
                </tr>
                <tr>
                    <td className="has-text-right">Resumo</td>
                    <td>{document.abstract}</td>
                </tr>
                <tr>
                    <td className="has-text-right">Data do Mapeamento ODS</td>
                    <td className="has-text-weight-bold">{document.date}</td>
                </tr>
            </tbody>
        </table>
    );
}

function SdgReportChart({ sdgEntries }) {
    const sdgCounter = new Map()

    sdgEntries.forEach(element => {
        if (sdgCounter.get(element.sdgGoal) === undefined) {
            sdgCounter.set(element.sdgGoal, 1)
        }
        else {
            sdgCounter.set(element.sdgGoal, sdgCounter.get(element.sdgGoal) + 1)
        }
    });

    const data = []

    sdgCounter.forEach(
        (v, k) => { data.push({ sdg: k, value: v }) }
    )
    data.sort((a, b) => { return b.value - a.value })

    const labels = []
    const values = []
    const barColors = []

    data.forEach(
        entry => {
            labels.push("ODS ".concat(entry.sdg))
            values.push(entry.value)
            barColors.push(sdgColors.get("sdg".concat(entry.sdg)))
        }
    )

    const chartData = {
        labels: labels,
        datasets: [
            {
                backgroundColor: barColors,
                data: values
            }
        ]
    }

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "Quantitativo de Metas relacionadas a cada ODS",
            },
        },
    };


    return (
        <div style={{ width: '600px' }} className="mx-auto box">
            <Bar data={chartData} options={options} />
        </div>
    );
}

function SdgReportTable({ sdgEntries }) {
    const sdgs = new Map()

    sdgEntries.forEach(target => {
        if (sdgs.get(target.sdgGoal) === undefined) {
            const targets = [target.sdgTarget]
            sdgs.set(target.sdgGoal, { goal: target.sdgGoal, targets: targets })
        }
        else {
            const entry = sdgs.get(target.sdgGoal)
            if (entry.targets.includes(target.sdgTarget) === false) {
                entry.targets.push(target.sdgTarget)
                sdgs.set(target.sdgGoal, entry)
            }
        }
    });

    const goals = []
    sdgs.forEach((v, k) => {
        v.targets.sort((a, b) => { return a - b })
        goals.push(v)
    })
    goals.sort((a, b) => { return a.goal - b.goal })

    return (
        <table className="table mx-auto">
            <thead>
                <tr>
                    <th className="has-text-centered">Objetivo contemplado</th>
                    <th className="has-text-centered">Metas contempladas</th>
                </tr>
            </thead>

            <tbody>
                {
                    goals.map(goal => <tr>
                        <td className="has-text-centered">
                            <figure className="image is-96x96 is-inline-block">
                                <img alt="" src={`../img/sdg_icons/sdg_goal_${goal.goal}.png`} />
                            </figure>
                        </td>
                        <td>
                            <span className="tags">
                                {goal.targets.map(target => <span className={`tag is-sdg-${goal.goal} is-medium`} > {target}</span>)}
                            </span>
                        </td>
                    </tr>)
                }
            </tbody>
        </table >
    );
}

export default SdgReport;

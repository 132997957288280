import { useState } from "react"

function SdgRanking() {
    const [modalStatus, setModalStatus] = useState("")

    function toggleModal(modalStatus) {
        modalStatus === "" ? setModalStatus("is-active") : setModalStatus("")
    }

    return (
        <article className="box content">
            <h1 className="title">Relacionamento aos Objetivos de Desenvolvimento Sustentável</h1>
            <p>
                O NormaLIS, além de coletar normas brasileiras, realiza a análise de seu conteúdo.
                Essa análise busca avaliar a "adesão" de cada norma
                aos <span className="is-clickable" onClick={() => toggleModal(modalStatus)}><strong className="has-text-info">Objetivos de Desenvolvimento Sustentável</strong></span>,
                mediante uma técnica chamada <strong>cover density ranking</strong> com termos previamente definidos e relacionados
                aos <strong>Objetivos</strong> e <strong>Metas</strong> dos <strong><dfn title="Objetivos de Desenvolvimento Sustentável">ODS</dfn></strong>.
            </p>
            <p>
                A ferramenta utilizada para o ranqueamento faz parte do SGBD PostgreSQL e não recebeu qualquer alteração.
            </p>
            <p>
                A explicação a seguir descreve, de maneira geral, o que é o <strong>cover density ranking</strong>:
            </p>
            <div className="message">
                <div className="message-body">
                    <p>
                        <cite>
                            O ranqueamento busca mensurar quão relevantes são determinados documentos em relação a uma consulta específica,
                            de modo que, quando há muitas correspondências, as mais relevantes devem ser recuperadas primeiro.
                            O PostgreSQL fornece duas funções de classificação predefinidas, que levam em consideração informações lexicais, de proximidade e
                            estruturais; ou seja, elas consideram com que frequência os termos da consulta aparecem no documento, quão próximos estão os termos
                            no documento e quão importante é a parte do documento onde ocorrem. No entanto, o conceito de relevância é vago e muito específico da
                            aplicação.
                        </cite>
                    </p>
                    <p>Fonte: <a href="https://www.postgresql.org/docs/current/textsearch-controls.html#TEXTSEARCH-RANKING" target="_blank" rel="noreferrer">documentação do SGBD PostgreSQL</a>. Tradução automática para o português (chatGPT) revisada</p>
                </div>
            </div>
            <p>
                A adesão das normas a determinadas Metas e Objetivos do Desenvolvimento Sustentável é mensurada mediamente o ranqueamento de uma lista
                com 2947 termos levantados pelo LIS, e cada termo dessa lista é associado a uma Meta (e consequentemente um Objetivo).
            </p>
            <p>
                O NormaLIS efetua a busca por cada um dos termos e obtém uma pontuação, que vai de zero até 1, e que indica a adesão da norma analisada à Meta
                relacionada ao termo.
                O sistema desconsidera pontuações muito baixas na mensuração da adesão. A nota de corte foi estabelecida de maneira empírica e pode ser alterada caso
                seja necessário. Atualmente (maio de 2024), é usada a pontuação <code>0,005</code> para indicar o limite mínimo de adesão do termo.
            </p>

            <figure className="image box">
                <img src="./../img/sdg_ranking_sample_01.png" alt="Exemplo de ranqueamento: 17 termos ligados ao Objetivo 9 ocorrem na norma analisada" />
                <figcaption>Exemplo de ranqueamento: 17 termos ligados ao Objetivo 9 ocorrem na norma analisada</figcaption>
            </figure>

            <p>
                Uma limitação dessa metodologia de ranqueamento ocorre em normas cujo texto somente revoga outra(s) norma(s). O exemplo
                a seguir ilustra essa situação:
            </p>

            <figure className="image box">
                <img src="./../img/sdg_ranking_sample_02.png" alt="Decreto cujo texto contém somente a revogação de outra norma" />
                <figcaption>Decreto cujo texto contém somente a revogação de outra norma</figcaption>
            </figure>

            <p>
                Ainda que o ranqueamento não seja possível nesses casos, a relevância da norma no contexto
                da Sustentabilidade advém de sua recuperação nos portais de busca pesquisados.
            </p>

            <h3 className="subtitle">
                Lista de termos relacionados aos Objetivos do Desenvolvimento Sustentável
            </h3>
            <p>
                Os termos utilizados no ranqueamento das normas derivam da tradução de uma outra lista,
                obtida de uma ferramenta chamada <a href="https://knowsdgs.jrc.ec.europa.eu/sdgmapper" target="_blank" rel="noreferrer">SDG Mapper</a>, desenvolvida
                pela Comissão Europeia.
            </p>
            <p>
                Essa ferramenta é capaz de realizar o ranqueamento de textos em língua portuguesa de maneira semelhante ao NormaLIS.
                Todavia, essa funcionalidade é implementada mediante tradução prévia e automática dos documentos submetidos na plataforma,
                que opera somente com textos em inglês.
            </p>
            <p>
                Além da tradução dos termos, foi também realizada sua adaptação para o contexto brasileiro. Dado que o
                NormaLIS aplica-se às normas brasileiras, alguns itens da lista original foram removidos.
            </p>
            <p>
                Como exemplo, pode-se citar o termo <em>Iniciativa para a Grande Muralha Verde do Sáara e o Sahel</em>, que constava
                na lista não traduzida, relacionando-se ao ODS 15 (<em>Vida Terrestre</em>) Meta 15.2.
                Durante o trabalho de tradução, considerou-se que tal termo não ocorreria no conjunto de normas brasileiras, e
                que portanto não seria necessária a sua inclusão.
            </p>
            <p>
                Além da adesão de uma determinada norma aos Objetivos do Desenvolvimento Sustentável, que
                pode ser visualizada na pesquisa por palavras-chave, o NormaLIS também disponibiliza informações
                agregadas sobre todos os documentos coletados. O gráfico a seguir ilustra quais ODS ocorrem com
                maior frequência:
            </p>

            <figure className="image box">
                <img src="./../img/sdg_ranking_sample_03.png" alt="Dados agregados sobre os ODS nas normas coletadas" />
                <figcaption>Dados agregados sobre os ODS nas normas coletadas</figcaption>
            </figure>

            <div className={`modal ${modalStatus}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <figure style={{ width: '300px' }} className="image mx-auto">
                            <img src="../img/sdg_visual_id/ods_logo.png" alt="Logotipo dos Objetivos de Desenvolvimento Sustentável" />
                        </figure>
                    </header>
                    <section className="modal-card-body">
                        <p>
                            Os <strong>Objetivos de Desenvolvimento Sustentável</strong> são
                            compostos por 17 objetivos gerais e diversas metas específicas relacionadas. São definidos da seguinte maneira no site da ONU:
                        </p>
                        <div className="message">
                            <p className="message-body">
                                Os Objetivos de Desenvolvimento Sustentável são um apelo global à ação para acabar com a pobreza,
                                proteger o meio ambiente e o clima e garantir que as pessoas, em todos os lugares,
                                possam desfrutar de paz e de prosperidade. Estes são os objetivos para os quais as Nações Unidas estão
                                contribuindo a fim de que possamos atingir a Agenda 2030 no Brasil.
                            </p>
                            <p>
                                Fonte: <a href="https://brasil.un.org/pt-br/sdgs" target="_blank" rel="noreferrer"><strong>site da Organização das Nações Unidas</strong></a>
                            </p>
                        </div>

                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-info" onClick={() => toggleModal()}>Fechar</button>
                    </footer>
                </div>
            </div>
        </article >
    );
}





export default SdgRanking;

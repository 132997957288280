import React from "react";

function NormContents(props) {
    const contents = props.contents;

    return (
        <article className="box is-size-7" dangerouslySetInnerHTML={{ __html: contents }}></article>
    );
}

export default NormContents;

import React from "react";
import { useState } from "react";
import { DOCUMENT_TYPES } from "../../grapqhl/norms_queries";
import { useQuery } from "@apollo/client";
import { Loading } from "../ProgressIndicators";

const docTypes = new Map()

function DocumentTypes({ documentTypesHandler }) {
    const [selectedDocTypes, setSelectedDocTypes] = useState(docTypes)

    const { data, loading, error } = useQuery(DOCUMENT_TYPES);
    if (loading) return <Loading />
    if (error) return <p>Não foi possível carregar os tipos de normas</p>;

    const availableDocTypes = data.documentTypes.documentTypes

    if (selectedDocTypes.size === 0) {
        selectedDocTypes.set("all", {
            id: "all",
            name: "Desmarcar todos",
            isSelected: true,
        })

        availableDocTypes.forEach(
            docType => {
                selectedDocTypes.set(
                    docType.name,
                    { name: docType.name, isSelected: true, id: docType.name }
                )
            });

        setSelectedDocTypes(selectedDocTypes)
        handleDocTypesFilter(selectedDocTypes)
    }

    function handleDocTypesFilter(docTypesSelection) {
        const newDocTypes = []

        availableDocTypes.forEach(
            (docType) => {
                if (docTypesSelection.get(docType.name) !== undefined && docTypesSelection.get(docType.name).isSelected === true) {
                    newDocTypes.push({ normTypeId: docType.id })
                }
            }
        )

        documentTypesHandler(newDocTypes)
    }

    function toggleSelected(docTypeName, newStatus, docTypeId) {
        if (docTypeId === "all" && newStatus === true) {
            selectedDocTypes.forEach(
                docType => {
                    selectedDocTypes.set(docType.id, { id: docType.id, name: docType.name, isSelected: true })
                }
            )

            selectedDocTypes.set("all", { id: "all", name: "Desmarcar todas", isSelected: true })
        }

        else if (docTypeId === "all" && newStatus === false) {
            selectedDocTypes.forEach(
                docType => {
                    selectedDocTypes.set(docType.id, { id: docType.id, name: docType.name, isSelected: false })
                }
            )
            selectedDocTypes.set("all", { id: "all", name: "Marcar todas", isSelected: false })
        }

        if (selectedDocTypes.get("all").isSelected === true && docTypeId !== "all") {
            selectedDocTypes.forEach(
                docType => {
                    selectedDocTypes.set(docType.id, { id: docType.id, name: docType.name, isSelected: false })
                }
            )
            selectedDocTypes.set("all", { id: "all", name: "Marcar todas", isSelected: false })
            selectedDocTypes.set(docTypeId, { id: docTypeId, name: docTypeName, isSelected: true })
        }

        else selectedDocTypes.set(docTypeId, { id: docTypeId, name: docTypeName, isSelected: newStatus })


        setSelectedDocTypes(selectedDocTypes)
        handleDocTypesFilter(selectedDocTypes)
    }

    return (
        <ol className="tags">
            {
                [...selectedDocTypes.values()].sort(sortNormTypes).map(
                    docType => {
                        return <DocTypeListItem
                            isSelected={docType.isSelected}
                            docTypeName={docType.name}
                            onClick={toggleSelected}
                            docTypeId={docType.id}
                        />
                    }
                )
            }
        </ol>
    );
}

function sortNormTypes(a, b) {
    if (b.id === "all") return 1
    if (a.name < b.name) return -1
    else if (a.name > b.name) return 1
    return 0
}

function DocTypeListItem({ isSelected, docTypeName, onClick, docTypeId }) {
    function onClickHandler() {
        onClick(docTypeName, !isSelected, docTypeId)
    }

    const isLight = (isSelected === true) ? "" : "is-light"
    const style = (docTypeId === "all") ? `tag is-warning is-clickable ${isLight}` : `tag is-link is-clickable ${isLight}`

    return (
        <li
            onClick={onClickHandler}
            className={`${style}`}
        >
            {docTypeName}
        </ li>
    );
}

export default DocumentTypes

import React from "react";
import { useNavigate } from "react-router-dom";

function Error404() {
    const navigate = useNavigate()

    return (
        <div className="container is-max-desktop">
            <section className="section is-big">
                <div className="box">
                    <h1 className='title is-1'>NormaLIS</h1>
                    <h2 className="subtitle is-6">Pesquisa de Normas da Sustentabilidade</h2>
                    <h1 className="title is-4 my-6">Página não encontrada</h1>
                    <button className="button is-primary" onClick={() => navigate(-1)}>Voltar</button>
                </div>
            </section>
        </div>
    );
}

export { Error404 };

import React, { Fragment } from "react";
import { Loading } from "../ProgressIndicators";
import { useQuery } from "@apollo/client";
import { QUERY_NORMS } from "../../grapqhl/norms_queries";
import { useState } from "react";
import DocumentDetails from "./DocumentDetails";

function QueryResults({ queryParams, nextPageHandler }) {
    const { data, loading, error, refetch } = useQuery(
        QUERY_NORMS, {
        variables: { count: queryParams.count, afterCursor: queryParams.afterCursor, filter: queryParams.filter }
    },
    )
    if (loading) return <Loading />
    if (error) return <p>Não foi possível carregar os resultados.</p>

    refetch()
    const queryResults = data.norms.edges
    const pageInfo = data.norms.pageInfo

    return (
        <Fragment>
            {
                pageInfo.pageNodesCount >= 50 ?
                    <p className="notification is-danger has-text-centered p-2 is-size-6">
                        Exibindo o número máximo (50) de resultados
                    </p>
                    :
                    <p className="notification is-info has-text-centered p-2 is-size-6">
                        Exibindo {pageInfo.pageNodesCount} resultado(s)
                    </p>
            }

            <table className="table is-striped is-narrow">
                <thead>
                    <tr>
                        <th>Título</th>
                        <th>Tipo de norma</th>
                        <th>Ementa</th>
                        <th>Localidade</th>
                        <th>Data</th>
                        <th>Outras informações</th>
                        <th>Link original</th>
                    </tr>
                </thead>
                <tbody className="is-small">
                    {queryResults.length === 0
                        ? <tr><td>Sem resultados</td></tr>
                        : queryResults.map((entry) =>
                            <NormsQueryItem
                                key={entry.node.documentID}
                                id={entry.node.documentID}
                                title={entry.node.title}
                                type={entry.node.type}
                                abstract={entry.node.abstract}
                                locationName={entry.node.location}
                                date={entry.node.date}
                                externalUrl={entry.node.externalURL}
                            />
                        )
                    }
                </tbody>
            </table >
        </Fragment>
    );
}

function NormsQueryItem({ id, title, type, abstract, locationName, date, externalUrl }) {
    const [modalStatus, setModalStatus] = useState("")

    function toggleModalStatus() {
        modalStatus === "" ? setModalStatus("is-active") : setModalStatus("")
    }

    return (
        <tr>
            {
                modalStatus === "is-active" ?
                    <NormDetailsModal modalToggle={toggleModalStatus} documentId={id} /> :
                    ""
            }
            <td>{title}</td>
            <td>{type}</td>
            <td>{abstract}</td>
            <td>{locationName}</td>
            <td>{date}</td>
            <td><span className="tag is-danger is-clickable" onClick={() => toggleModalStatus()}>Detalhes</span></td>
            <td><a className="tag is-info is-clickable" href={externalUrl} target="_blank" rel="noreferrer">Visitar</a></td>
        </tr >
    );
}


function NormDetailsModal({ modalToggle, documentId }) {
    return (
        <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card is-norm-details">
                <header class="modal-card-head">
                    <p class="modal-card-title">Detalhes da norma</p>
                    <div className="has-text-right">
                        <button class="button is-danger" onClick={modalToggle}>Fechar</button>
                    </div>
                </header>
                <section className="modal-card-body">
                    <DocumentDetails documentId={documentId} />
                </section>
            </div>
        </div>
    );
}

export default QueryResults;

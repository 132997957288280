import { Fragment } from "react"

const sourcesComments = new Map()

sourcesComments.set(
    "aggregateData",
    <Fragment>
        <div className="content">
            <div>Conteúdo em elaboração</div>
        </div>
    </Fragment>
);

sourcesComments.set(
    "Brasil",
    <Fragment>
        <div className="content">
            <p>
                As normas federais podem ser pesquisadas em duas plataformas: <a href="https://lexml.gov.br" target="_blank" rel="noreferrer">
                    Rede de Informação Legislativa e Jurídica (LexML)</a> e <a href="https://normas.leg.br/" target="_blank" rel="noreferrer">
                    <strong>Normas</strong>.leg.br</a>.
            </p>
            <p>
                O <strong>LexML</strong> adota definição ampla de <em>Norma Jurídica</em>, e inclui, além de Leis, Decretos, Resoluções etc.,
                outras categorias de documentos, como <em>Jurisprudência</em> e <em>Doutrina</em>. Ainda que inclua algumas normas de outros
                estados e municípios brasileiros, sua adoção por estes entes federativos é voluntária.
            </p>
            <p>
                O portal <strong>Normas.leg.br</strong> disponibiliza normas em seu sentido mais convencional
                - <em> ato normativos instituídos por autoridades competentes</em>. Uma característica especialmente
                interessante do Normas.leg.br é a possibilidade de visualizar as alterações de uma norma ao longo do tempo,
                bem como todo o processo legislativo que deu origem àquela norma.
            </p>
            <hr />
            <p>
                <em>Especiais agradecimentos ao servidor João Alberto de Oliveira Lima, Analista de Informática Legislativa do Senado,
                    pelas orientações fornecidas em relação às APIs das plataformas de dados consumidas pelo NormaLIS.
                </em>
            </p>
        </div>
    </Fragment>
);

sourcesComments.set("Acre",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Alagoas",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Amapá",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Amazonas",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Bahia",
    <Fragment>
        <div className="content">
            <p>Durante o desenvolvimento do NormaLIS (2024), o Estado da Bahia foi o único a possuir o instrumento <em>Decreto Financeiro</em>.
                Conforme descrito na seção <em>Metodologia</em>, devido ao grande número de normas desse tipo,
                decidiu-se pela diferenciação desse subtipo de Decreto.</p>
        </div>
    </Fragment>
);

sourcesComments.set("Ceará",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Espírito Santo",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Goiás",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Maranhão",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Mato Grosso",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Mato Grosso do Sul",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Minas Gerais",
    <Fragment>
        <div className="content">
            <p>
                A URL de pesquisa apresentada mais abaixo aponta para a API de consulta de normas do Estado de Minas Gerais.
                Acessada diretamente por esse link, a API irá retornar conteúdo em formato XML. Mais informações sobre essa
                API podem ser obtidas <a href="https://dadosabertos.almg.gov.br/ws/ajuda/sobre" target="_blank" rel="noreferrer">aqui</a>.
            </p>
            <p>
                Para realizar consultas da maneira convencional, a Assembleia Legislativa conta com
                um <a href="https://www.almg.gov.br/atividade-parlamentar/leis/legislacao-mineira/" target="_blank" rel="noreferrer">portal de pesquisa de normas</a>.
            </p>
        </div>
    </Fragment>
);

sourcesComments.set("Pará",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Paraíba",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Paraná",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Pernambuco",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Piauí",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Rio de Janeiro",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Rio Grande do Norte",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Rio Grande do Sul",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Rondônia",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Roraima",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Santa Catarina",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("São Paulo",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Sergipe",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Tocantins",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

sourcesComments.set("Distrito Federal",
    <Fragment>
        <div className="content"></div>
    </Fragment>
);

export default sourcesComments;

import React from "react";
import QueryResults from "../components/norms_query/QueryResults";
import QueryFilters from "../components/norms_query/QueryFilters";
import { useQuery } from "@apollo/client";
import { QUERY_NORMS } from "../grapqhl/norms_queries";
import { Loading } from "../components/ProgressIndicators";
import { useState } from "react";

const defaultQuery = {
    count: 50,
    afterCursor: null,
    beforeCursor: null,
    filter: {
        yearFrom: 1988,
        yearTo: new Date().getFullYear(),
        yearSort: "DESC",
        locations: [],
        locationsSort: "ASC",
        normTypes: [],
        normTypesSort: "ASC"
    }
}

function NormsQuery() {
    const [queryParams, setQueryParams] = useState(defaultQuery)

    const handleDocumentTypes = (newDocumentTypes) => {
        queryParams.filter.normTypes = newDocumentTypes
        queryParams.afterCursor = null
        queryParams.beforeCursor = null
        setQueryParams({ ...queryParams })
    }

    const handleLocations = (newLocations) => {
        queryParams.filter.locations = newLocations
        queryParams.afterCursor = null
        queryParams.beforeCursor = null
        setQueryParams({ ...queryParams })
    }

    function handleYearOrdering(e) {
        queryParams.filter.yearSort = e.target.value
        queryParams.afterCursor = null
        queryParams.beforeCursor = null
        setQueryParams({ ...queryParams })
    }

    function handleYearsRange(e) {
        // Wait user input of at least 4 digits to do something
        if (e.target.value.length < 4) { return }

        if (e.target.value < 1988) {
            e.target.value = 1988
        }

        switch (e.target.name) {
            case "yearFrom":
                if (e.target.value > queryParams.filter.yearTo) {
                    e.target.value = queryParams.filter.yearTo
                } else {
                    queryParams.filter.yearFrom = e.target.value
                }
                break;

            case "yearTo":
                if (e.target.value < queryParams.filter.yearFrom) {
                    e.target.value = queryParams.filter.yearFrom
                } else {
                    queryParams.filter.yearTo = e.target.value
                }
                break;

            default:
                console.log("nobody should see this happening")
                break;
        }

        queryParams.afterCursor = null
        queryParams.beforeCursor = null

        setQueryParams({ ...queryParams })
    }

    return (
        <div className="container">
            <section className="section">
                <h1 className="title is-3">Consulta livre</h1>
                <h2 className="subtitle is-6">Clique nas etiquetas para aplicar a filtragem</h2>
                <QueryFilters
                    documentTypesHandler={handleDocumentTypes}
                    locationsHandler={handleLocations}
                    yearOrderingHandler={handleYearOrdering}
                    yearRangeHandler={handleYearsRange}
                />
                <QueryResults queryParams={queryParams} />
            </section>
        </div>
    );
}

export default NormsQuery;

const sdgColors = new Map();
sdgColors.set("sdg1", "#E5243B");
sdgColors.set("sdg2", "#DDA63A");
sdgColors.set("sdg3", "#4C9F38");
sdgColors.set("sdg4", "#C5192D");
sdgColors.set("sdg5", "#FF3A21");
sdgColors.set("sdg6", "#26BDE2");
sdgColors.set("sdg7", "#FCC30B");
sdgColors.set("sdg8", "#A21942");
sdgColors.set("sdg9", "#FD6925");
sdgColors.set("sdg10", "#DD1367");
sdgColors.set("sdg11", "#FD9D24");
sdgColors.set("sdg12", "#BF8B2E");
sdgColors.set("sdg13", "#3F7E44");
sdgColors.set("sdg14", "#0A97D9");
sdgColors.set("sdg15", "#56C02B");
sdgColors.set("sdg16", "#00689D");
sdgColors.set("sdg17", "#19486A");

function makeRgb() {
    let colors = [
        Math.floor(Math.random() * 255),
        Math.floor(Math.random() * 255),
        Math.floor(Math.random() * 255)
    ]
    const finalColor = "rgba(" + colors.join(",") + ",1)"

    return finalColor;
}

export { makeRgb, sdgColors }

import React from 'react';
import { REPORT_ACTIVE_NORMS_COUNT_BY_YEAR_AND_STATE } from '../../grapqhl/reports';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useQuery } from '@apollo/client';
import { makeRgb } from '../../helpers/helpers';
import { Loading } from '../ProgressIndicators';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function ReportNormsByYearAndState() {
    const { data, loading, error } = useQuery(REPORT_ACTIVE_NORMS_COUNT_BY_YEAR_AND_STATE);
    if (loading) return <Loading />
    if (error) return <p>Não foi possível exibir os dados</p>

    const years = createLabels(data.reportActiveNormsCountByYearAndState.entries);
    const reportDate =
        new Date(data.reportActiveNormsCountByYearAndState.reportDate)
            .toLocaleString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' });

    const chartData = {
        labels: years,
        datasets: createDatasets(data.reportActiveNormsCountByYearAndState.entries),
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                align: 'start',
            },
        },
    };

    return (
        <div>
            <h3 className='subtitle'>
                Normas Estaduais e Distrito Federal por Ano e Estado
                <br />
                <span className='has-text-weight-normal is-size-6'>Atualizado em {reportDate}</span></h3>

            <div style={{ width: '700px' }} className='mx-auto'>
                <Bar options={options} data={chartData} />
            </div>
        </div>
    );
}

function createLabels(entries) {
    const years = [];
    const firstYear = entries[0].year;
    const finalYear = new Date().getFullYear();

    for (let index = firstYear; index <= finalYear; index++) {
        years.push(index);
    }

    return years;
}

function createDatasets(entries) {
    const reportYears = createLabels(entries);
    const datasets = new Map();

    entries.forEach(entry => {
        if (!datasets.get(entry.stateName)) {
            datasets.set(
                entry.stateName,
                {
                    label: entry.stateName,
                    backgroundColor: makeRgb(),
                    borderWidth: 0,
                    stack: true,
                    data: Array(reportYears.length).fill(0),
                },
            )
        }
    });

    entries.forEach(entry => {
        const pos = reportYears.indexOf(entry.year);
        datasets.get(entry.stateName).data[pos] = entry.count;
    })

    const response = [];
    datasets.forEach((v, k) => response.push(v));

    return response;
}

export default ReportNormsByYearAndState;

import React from "react";

function Loading() {
    return (
        <section className="section">
            <div className="container is-max-desktop">
                <p className="has-text-centered">Carregando informações...</p>
                <progress className="progress is-large is-info"></progress>
            </div>
        </section>
    );
}

function Searching() {
    return (
        <section className="section">
            <div className="container is-max-desktop">
                <p className="has-text-centered">Pesquisando...</p>
                <progress className="progress is-large is-info"></progress>
            </div>
        </section>
    );
}

function ProcessingSdgReport() {
    return (
        <section className="section">
            <div className="container is-max-desktop">
                <p className="has-text-centered">Analisando documento...</p>
                <progress className="progress is-large is-danger"></progress>
                <p className="has-text-centered">
                    O tempo de análise do documento depende do tamanho do arquivo submetido originalmente
                </p>
                <p className="has-text-centered">
                    Caso ocorra alguma falha neste processo, será necessário enviar o arquivo novamente
                </p>
            </div>
        </section>
    );
}

function UploadingFile() {
    return (
        <div>
            <p className="has-text-centered">Enviando arquivo...</p>
            <progress className="progress is-small is-info"></progress>
        </div>
    );
}

export { Loading, Searching, ProcessingSdgReport, UploadingFile };

import React, { useState } from "react";
import UserDocumentFileUpload from "../components/sdg_mapper/SubmitedDocumentDetails";
import { useMutation } from "@apollo/client";
import { SUBMIT_DOCUMENT_FOR_SDG_MAPPING } from "../grapqhl/sdg_mapper";

const UPLOAD_STATUS_IDLE = "Enviar arquivo"
const UPLOAD_STATUS_SENDING = "Enviando..."
const UPLOAD_STATUS_SENT = "Arquivo enviado"
const UPLOAD_STATUS_ERROR = "Houve um erro"

function SdgMapper() {
    const [file, setFile] = useState()
    const [docTitle, setDocTitle] = useState("")
    const [docAuthor, setDocAuthor] = useState("")
    const [docAbstract, setDocAbstract] = useState("")
    const [docSubmissionData, setDocSubmissionData] = useState()
    const [uploadStatus, setUploadStatus] = useState(UPLOAD_STATUS_IDLE)

    const [submitDocument] = useMutation(SUBMIT_DOCUMENT_FOR_SDG_MAPPING)

    const handleFileChange = (e) => {
        setDocSubmissionData()

        if (e.target.files) {
            setFile(e.target.files[0]);
            setUploadStatus(UPLOAD_STATUS_IDLE)
        }
    };

    function handleFieldsChange(e) {
        switch (e.target.id) {
            case "document_title":
                setDocTitle(e.target.value)
                break;

            case "document_authorship":
                setDocAuthor(e.target.value)
                break;

            case "document_abstract":
                setDocAbstract(e.target.value)
                break;

            default:
                break;
        }
    }

    const handleUpload = async () => {
        if (file) {
            setUploadStatus(UPLOAD_STATUS_SENDING)

            const formData = new FormData();
            formData.append("file", file);
            formData.append("document_authorship", docAuthor);
            formData.append("document_title", docTitle);
            formData.append("document_abstract", docAbstract)

            try {
                const result = await fetch(process.env.REACT_APP_NORMALIS_API_URL + '/upload', {
                    method: 'POST',
                    body: formData,
                });

                const docSubmissionData = await result.json();
                console.log("submission data ", docSubmissionData)
                const { data } = await submitDocument({
                    variables: {
                        fileId: docSubmissionData.document_id,
                        documentTitle: docTitle,
                        documentAuthorship: docAuthor,
                        documentAbstract: docAbstract,
                    }
                })
                setDocSubmissionData(docSubmissionData)
                console.log("data is ", data)
                setUploadStatus(UPLOAD_STATUS_SENT)
            }
            catch (error) {
                console.log("error submiting document: ", error.message)
                setUploadStatus(UPLOAD_STATUS_ERROR)
            }
        }
    };

    return (
        <div className="container">
            <section className="section">
                <h1 className="title">Mapeamento ODS</h1>

                <div className="columns">
                    <div className="column">
                        <form className="form">
                            <div className="field">
                                <label className="label">Título para o documento</label>
                                <div className="control">
                                    <input id="document_title" className="input" type="text" onChange={handleFieldsChange} />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Autoria</label>
                                <div className="control">
                                    <input id="document_authorship" className="input" type="text" onChange={handleFieldsChange} />
                                </div>
                                <p className="help">Informe autor(es), instituição ou responsável pelo documento</p>
                            </div>

                            <div className="field">
                                <label className="label">Resumo</label>
                                <div className="control">
                                    <textarea
                                        className="textarea"
                                        rows="4"
                                        id="document_abstract"
                                        onChange={handleFieldsChange}
                                    >
                                    </textarea>
                                    <p className="help">O resumo será exibido no relatório gerado. Contagem de caracteres: {docAbstract.length}</p>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Selecione o arquivo</label>
                                <div className="control">
                                    <input id="file" className="input" type="file" onChange={handleFileChange} />
                                </div>
                                <p className="help">Formato recomendado: PDF</p>
                            </div>
                        </form>
                    </div>

                    <div className="column">
                        {
                            file ?
                                <UserDocumentFileUpload
                                    file={file}
                                    uploadHandler={handleUpload}
                                    documentAuthor={docAuthor}
                                    documentTitle={docTitle}
                                    documentAbstract={docAbstract}
                                    uploadStatus={uploadStatus}
                                    docSubmissionData={docSubmissionData}
                                /> :
                                ""
                        }
                    </div>
                </div>
            </section>
        </div >
    )
}

export { SdgMapper, UPLOAD_STATUS_IDLE, UPLOAD_STATUS_SENDING, UPLOAD_STATUS_SENT, UPLOAD_STATUS_ERROR };

import { gql } from "@apollo/client";

const REPORT_ACTIVE_NORMS_COUNT_BY_YEAR = gql`
query ReportActiveNormsCountByYear {
	reportActiveNormsCountByYear{
		reportDate
		entries{
			count
			normStatus
			year
		}
	}
}`;

const REPORT_ACTIVE_NORMS_COUNT_BY_YEAR_AND_STATE = gql`
query ReportActiveNormsCountByYearAndState{
	reportActiveNormsCountByYearAndState{
		reportDate
		entries{
			count
			stateAbbreviation
			stateName
			status
			year
		}
	}
}`;

const REPORT_SDG_RELEVANCE_COUNT = gql`
query ReportSdgRelevanceCount {
	reportSdgRelevanceCount{
		reportDate
		entries{
			sdgGoal
			count			
		}
	}
}`;


const REPORT_NORMS_COUNT_BY_STATE = gql`
query ReportNormsCountByState{
	reportNormsCountByState{
		reportDate
		entries{
			stateName
			count
		}
	}
}`;

const REPORT_NORMS_COUNT_BY_TYPE_AND_FEDERATIVE_ENTITY = gql`
query ReportFederativeEntityNormsSummary($entityAbbreviation: ENTITY_ABBREVIATION!) {
	reportFederativeEntityNormsSummary(entityAbbreviation: $entityAbbreviation) {
		sourceName
		sourceUrl		
		lastUpdate
		entries{
			documentType
			count
		}
	}
}`;

const REPORT_NORMS_COUNT_BY_TYPE = gql`
query ReportNormsCountByType{
	reportNormsCountByType{
		lastUpdate
		entries{
			documentType
			count
		}
	}
}
`;

export {
	REPORT_ACTIVE_NORMS_COUNT_BY_YEAR,
	REPORT_ACTIVE_NORMS_COUNT_BY_YEAR_AND_STATE,
	REPORT_NORMS_COUNT_BY_STATE,
	REPORT_NORMS_COUNT_BY_TYPE_AND_FEDERATIVE_ENTITY,
	REPORT_NORMS_COUNT_BY_TYPE,
	REPORT_SDG_RELEVANCE_COUNT,
};

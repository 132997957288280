import React from "react";
import Locations from "./Locations";
import DocumentTypes from "./DocumentTypes";

function QueryFilters({ documentTypesHandler, locationsHandler, yearOrderingHandler, yearRangeHandler }) {
    return (
        <table className="table">
            <tbody>
                <tr>
                    <td>
                        <p className="has-text-weight-bold">Localidades</p>
                    </td>
                    <td>
                        <Locations locationsHandler={locationsHandler} />
                    </td>
                </tr>
                <tr>
                    <td className="has-text-weight-bold">Tipos de norma</td>
                    <td>
                        <DocumentTypes documentTypesHandler={documentTypesHandler} />
                    </td>
                </tr>
                <tr>
                    <td className="has-text-weight-bold">Período</td>
                    <td>
                        <span className="columns">
                            <span className="column is-one-third">
                                <span className="field is-horizontal">
                                    <input
                                        className="input is-small mr-4"
                                        placeholder="Ano início"
                                        type="number"
                                        id="yearFrom"
                                        name="yearFrom"
                                        min="1988"
                                        onChange={yearRangeHandler}
                                    />
                                    <input
                                        className="input is-small"
                                        placeholder="Ano fim"
                                        type="number"
                                        id="yearTo"
                                        name="yearTo"
                                        min="1988"
                                        onChange={yearRangeHandler}
                                    />
                                </span>
                            </span>
                            <span className="column">
                                <span className="control"
                                    onChange={yearOrderingHandler}
                                >
                                    <label className="radio">
                                        <span className="has-text-weight-bold mr-3">Ordenação (ano)</span>
                                        <input type="radio" name="year_ordering" defaultChecked value="DESC" />
                                        Descendente
                                    </label>
                                    <label className="radio">
                                        <input type="radio" name="year_ordering" value="ASC" />
                                        Ascendente
                                    </label>
                                </span>
                            </span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default QueryFilters;

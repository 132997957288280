import React from 'react';
import { REPORT_ACTIVE_NORMS_COUNT_BY_YEAR } from '../../grapqhl/reports';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useQuery } from '@apollo/client';
import { Loading } from '../ProgressIndicators';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function ReportNormsByYear() {
    const { data, loading, error } = useQuery(REPORT_ACTIVE_NORMS_COUNT_BY_YEAR);
    if (loading) return <Loading />
    if (error) return <p>Não foi possível exibir os dados</p>

    const years = createLabels(data.reportActiveNormsCountByYear.entries);
    const reportDate =
        new Date(data.reportActiveNormsCountByYear.reportDate)
            .toLocaleString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })

    const chartData = {
        labels: years,
        datasets: createDatasets(data.reportActiveNormsCountByYear.entries),
    };

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2,
        plugins: {
            legend: {
                position: 'bottom',
                display: false,
            },
            title: {
                display: true,
                text: "Quantitativo de normas brasileiras por ano - 1988 até o presente",
            },
        },
    };

    return (
        <div>
            <h3 className='subtitle'>
                Normas Estaduais e Distrito Federal por ano<br />
                <span className='has-text-weight-normal is-size-6'>Atualizado em {reportDate}</span>
            </h3>
            <p>
                No gráfico a seguir, nota-se o crescimento da quantidade de normas especialmente a partir do ano de
                1995. Cabe salientar o acontecimento da <strong>Conferência das Nações Unidas sobre Meio Ambiente e Desenvolvimento</strong> alguns
                anos antes, em Janeiro de 1992 - evento mais conhecido como <strong>Eco-92</strong>.
            </p>
            <p>
                Não foi feita uma análise qualitativa das normas a fim de verificar se esse aumento da quantidade de normas
                guarda alguma correção com a Eco-92.
            </p>

            <div style={{ width: '500px' }} className='mx-auto'>
                <Bar options={options} data={chartData} />
            </div>
        </div>
    );
}

function createLabels(entries) {
    const years = [];
    const firstYear = entries[0].year;
    const finalYear = new Date().getFullYear();

    for (let index = firstYear; index <= finalYear; index++) {
        years.push(index);
    }

    return years;
}

function createDatasets(entries) {
    const reportYears = createLabels(entries);

    const dataset = [
        {
            label: "Quantitativo Estaduais e DF",
            backgroundColor: 'rgba(46, 163, 242, 1)',
            borderWidth: 0,
            data: Array(reportYears.length).fill(0),
        },
    ];

    entries.forEach(entry => {
        const pos = reportYears.indexOf(entry.year);
        dataset[0].data[pos] = entry.count;
    })

    return dataset;
}

export default ReportNormsByYear;

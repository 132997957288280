import React from "react";
import { useNavigate } from "react-router-dom";

function Error500() {
    const navigate = useNavigate()

    return (<div className="container is-max-desktop">
        <section className="section is-big">
            <div className="box">
                <h1 className='title is-1'>NormaLIS</h1>
                <h2 className="subtitle is-6">Pesquisa de Normas da Sustentabilidade</h2>
                <h2 className="title is-4 my-6">O documento pesquisado não pode ser exibido</h2>
                <button className="button is-primary" onClick={() => navigate(-1)}>Voltar</button>
            </div>
        </section>
    </div>
    );
}

function Error500SdgMapping() {
    const navigate = useNavigate()
    return (<div className="container is-max-desktop">
        <section className="section is-big">
            <div className="card">
                <div className="card-content">
                    <h1 className='title is-1'>NormaLIS</h1>
                    <h2 className="subtitle">Mapeamento de Objetivos de Desenvolvimento Sustentável</h2>

                    <div className="message is-danger">
                        <div className="message-body">
                            <p className="has-text-weight-semibold has-text-centered">O documento solicitado não pode ser exibido</p>
                            <p>
                                Provavelmente o documento foi removido de nossa base de dados devido a alterações do sistema.
                                Você pode enviá-lo novamente para o mapeamento dos Objetivos de Desenvolvimento Sustentável
                            </p>
                        </div>
                    </div>


                </div>

                <footer className="card-footer">
                    <button
                        className="button card-footer-item is-primary"
                        onClick={() => navigate("/")}>
                        Ir para a página inicial
                    </button>

                    <button className="card-footer-item button is-danger"
                        onClick={() => navigate("/mapeador_ods")}>
                        Submeter um novo documento
                    </button>
                </footer>
            </div>
        </section>
    </div>
    );
}

export { Error500, Error500SdgMapping };

import React from "react";
import { useState } from "react";
import { LOCATIONS } from "../../grapqhl/norms_queries";
import { Loading } from "../ProgressIndicators";
import { useQuery } from "@apollo/client";

function Locations({ locationsHandler }) {
    const [selectedLocations, setSelectedLocations] = useState([])

    const { data, loading, error } = useQuery(LOCATIONS);
    if (loading) return <Loading />
    if (error) return <p>Não foi possível carregar as localidades</p>;
    const locations = data.locations.locations;

    if (selectedLocations.length === 0) {
        selectedLocations.push({
            id: "all",
            name: "Desmarcar todas",
            isSelected: true
        });

        locations.forEach(element => {
            selectedLocations.push({
                id: element.id,
                name: element.name,
                isSelected: true
            })
        });

        handleLocationsFilter()
    }

    function handleLocationsFilter() {
        setSelectedLocations([...selectedLocations])

        const newLocations = []
        selectedLocations.forEach(element => {
            if (element.id !== "all" && element.isSelected === true) {
                newLocations.push({ locationId: element.id })
            }
        });

        locationsHandler(newLocations)
    }

    function toggleLocationSelection(locationId, newStatus) {
        if (locationId === "all" && newStatus === true) {
            selectedLocations.forEach(element => {
                element.isSelected = true
            });
            selectedLocations.find((element) => element.id === "all").name = "Desmarcar todas"
        }
        else if (locationId === "all" && newStatus === false) {
            selectedLocations.forEach(element => {
                element.isSelected = false
            });
            selectedLocations.find((element) => element.id === "all").name = "Marcar todas"
        }

        const locationAll = selectedLocations.find((element) => element.id === "all")
        if (locationAll.isSelected && newStatus === false) {
            locationAll.isSelected = false
            locationAll.name = "Marcar todas"
            selectedLocations.forEach(element => {
                element.isSelected = false
            });
            newStatus = true
        }

        selectedLocations.find((element) => element.id === locationId).isSelected = newStatus

        handleLocationsFilter()
    }

    return (
        <ol className="tags">
            {selectedLocations.map(location =>
                <LocationItem
                    onClick={toggleLocationSelection}
                    key={location.id}
                    isSelected={location.isSelected}
                    locationId={location.id}
                    locationName={location.name} />
            )}
        </ol>
    );
}

function LocationItem({ isSelected, locationId, locationName, onClick }) {
    function onClickHandler() {
        onClick(locationId, !isSelected)
    }

    const isLight = (isSelected === true) ? "" : "is-light"
    const style = (locationId === "all") ? `tag is-link is-clickable ${isLight}` : `tag is-danger is-clickable ${isLight}`;

    return (
        <li
            onClick={onClickHandler}
            className={`${style}`}>
            {locationName}
        </ li>
    )
}

export default Locations

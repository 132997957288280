import React, { Fragment, useState } from "react";

import Search from "./search";
import { NormSourcesSummaries } from "./research_results";
import NormsQuery from "./norms_query";
import { SdgMapper } from "./sdg_mapper_submission";

function Home() {
    const [currentPage, setCurrentPage] = useState("search")

    function handleCurrentPage(currentPage) {
        setCurrentPage(currentPage)
    }

    const pageWidth = "container is-widescreen"

    return (
        <Fragment>
            <div className={pageWidth}>
                <div className="tabs is-centered">
                    <ul>
                        <a href={`#${currentPage}`} className={`${currentPage === "search" ? 'is-active' : ''}`} onClick={() => handleCurrentPage("search")}>Pesquisa</a>
                        <a href={`#${currentPage}`} className={`${currentPage === "query" ? 'is-active' : ''}`} onClick={() => handleCurrentPage("query")}>Consulta</a>
                        <a href={`#${currentPage}`} className={`${currentPage === "mapper" ? 'is-active' : ''}`} onClick={() => handleCurrentPage("mapper")}>Mapeador</a>
                        <a href={`#${currentPage}`} className={`${currentPage === "results" ? 'is-active' : ''}`} onClick={() => handleCurrentPage("results")}>Resultados</a>
                    </ul>
                </div>
            </div>
            <SetPageContents currentPage={currentPage} />
        </Fragment >
    );
}

function SetPageContents(data) {
    switch (data.currentPage) {
        case "search":
            return <Search />
        case "query":
            return <NormsQuery />
        case "results":
            return <NormSourcesSummaries />
        case "mapper":
            return <SdgMapper />
    }
}

export default Home;

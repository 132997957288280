import React from "react";

function SdgRankings(props) {
    const rankings = props.sdgRankings;

    return (
        <div className="message is-small">
            <div className="message-header">Objetivos do Desenvolvimento Sustentável (ODS) e Metas relacionadas</div>
            {
                rankings === null ?
                    <p className="message-body">
                        O texto da norma parece não possuir conteúdo que se relacione aos Objetivos do Desenvolvimento Sustentável
                    </p> :
                    <div >
                        {rankings.map((ranking, goalKey) =>
                            <div className="card" key={goalKey}>

                                <div className="card-content">
                                    <div className="media">
                                        <div className="media-left">
                                            <figure className="image is-96x96">
                                                <img alt="" src={`../img/sdg_icons/sdg_goal_${ranking.sdgGoal.sdgGoal}.png`} title={`Ocorrências: ${ranking.sdgGoal.count}`} />
                                            </figure>
                                        </div>
                                        <div className="tags">
                                            {ranking.sdgTargets.map((target, targetKey) =>
                                                <div className={`tag is-sdg-${ranking.sdgGoal.sdgGoal}`} key={targetKey} title={`Ocorrências: ${target.count}`}> Meta {target.sdgTarget}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
            }
        </div>
    );
}

export default SdgRankings;

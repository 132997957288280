import React, { Fragment } from "react";
import { useQuery } from '@apollo/client';
import { REPORT_NORMS_COUNT_BY_TYPE_AND_FEDERATIVE_ENTITY } from "../../grapqhl/reports";
import { Loading } from "../ProgressIndicators";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title, SubTitle } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import sourcesComments from "./SourcesComments";

import { stateNames } from "../../pages/research_results";

ChartJS.register(ArcElement, Tooltip, Legend, Title, SubTitle);

function NormSourceSummary(props) {
    const entityAbbreviation = props.data;
    const stateName = stateNames.get(entityAbbreviation);

    const { data, loading, error } = useQuery(REPORT_NORMS_COUNT_BY_TYPE_AND_FEDERATIVE_ENTITY, {
        variables: { entityAbbreviation: entityAbbreviation }
    });

    if (loading) return <Loading />;

    if (error) return (
        <Fragment>
            <div className="box">
                <NormsSourceComments stateName={stateName} />
            </div>
            <div className="notification is-danger box">
                <p>Não foi possível carregar os dados agregados sobre as normas.</p>
            </div>
        </Fragment>
    );

    return (
        <Fragment>
            <div className="box">
                <div className="content"><NormsSourceComments stateName={stateName} /></div>
                <div className="content"><NormsSourceChart data={data} /></div>
            </div>
        </Fragment>
    );
}

function NormsSourceComments(props) {
    const contents = sourcesComments.get(props.stateName)
    return (
        <Fragment>
            <h2 className="subtitle">{props.stateName}</h2>
            <p>{contents}</p>
        </Fragment>
    );
}

function NormsSourceChart(props) {
    const summary = props.data.reportFederativeEntityNormsSummary;
    const chartData = createChartData(summary.entries);
    const options = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2,
        radius: "80%",
        plugins: {
            legend: {
                position: 'right',
                // display: false,
                align: 'center',
            },
            title: {
                display: true,
                text: "Quantitativo por tipo de norma",
            },
            subtitle: {
                display: true,
                text: 'Clique no itens da legenda para ocultar/exibir',
            },
        },
    };

    const lastUpdate = new Date(summary.lastUpdate)
        .toLocaleString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })

    return (
        <Fragment>
            <div>
                <hr />
                <span className="has-text-weight-bold">{summary.sourceName}</span>
            </div>
            <div>
                <span className="has-text-weight-medium">URL: </span><a href={`${summary.sourceUrl}`} target="_blank" rel="noreferrer">{summary.sourceUrl}</a>
            </div>
            <div>
                <span className="has-text-right has-text-weight-medium">Última atualização: </span>{lastUpdate}
                <hr />
            </div>
            <Pie options={options} data={chartData} />
        </Fragment>
    );
}

function createChartData(entries) {
    const dataset = [
        {
            borderWidth: 2,
            hoverOffset: 50,
            label: "Quant.",
            data: [],
            backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
        }
    ];

    entries.forEach(e => {
        dataset[0].data.push(e.count)
    });


    const chartConfig = {
        labels: [],
        datasets: dataset,
        borderWidth: 1,
        backgroundColor: '#9BD0F5',
    };

    entries.forEach(e => {
        chartConfig.labels.push(e.documentType + " (" + e.count + ")")
    })

    return chartConfig;
}

export default NormSourceSummary;

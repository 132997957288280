import React from "react";
import SearchForm from "../components/search/SearchForm";


function Search() {
    return (
        <div className="container is-max-desktop">
            <div className="has-text-centered block">
                <h1 className='title is-1'>NormaLIS</h1>
                <h2 className="subtitle is-6">Pesquisa de Normas da Sustentabilidade</h2>
            </div>

            <div className="columns has-text-centered block">
                <div className="column"></div>
                <div className="column is-two-thirds"><SearchForm /></div>
                <div className="column"></div>
            </div>

            <div className="message">
                <div className="message-header">
                    Sobre o NormaLIS
                </div>
                <div className="message-body">
                    <p className="block">O NormaLIS é um serviço de busca por normas (leis, decretos, emendas, entre outros) brasileiras relacionadas à Sustentabilidade, ligado ao <strong>Laboratório de Informação para Sustentabilidade (LIS)</strong>.</p>
                    <p className="block">Nossa base de dados é atualizada constantemente e agrega normas de diversos entes da federação.</p>
                </div>
            </div>

            <footer className="footer">
                <div className="content has-text-centered">
                    <p>Criado pelo <strong>Laboratório de Informação para Sustentabilidade</strong></p>
                </div>
            </footer>
        </div>
    );
}

export default Search;

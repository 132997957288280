import { gql } from "@apollo/client";

const USER_SUBMITED_SDG_MAPPING = gql`
query UserSubmitedDocument ($documentId: ID!) {
	userSubmitedDocument(documentId: $documentId) {
		document {
			documentID
			title
			date
			abstract
			type
			keywords
			source
			externalURL
			fullText
		}
		sdgTargets {
			sdgGoal
			sdgTarget
		}
	}
}`;

const SUBMIT_DOCUMENT_FOR_SDG_MAPPING = gql`
mutation SubmitDocument(
	$fileId: ID!
	$documentAbstract: String!
	$documentAuthorship: String!
	$documentTitle: String!
) {
	submitDocumentForSdgMapping(
		input: {
			fileId: $fileId
			documentAbstract: $documentAbstract
			documentAuthorship: $documentAuthorship
			documentTitle: $documentTitle
		}
	) {
		documentId
	}
}`;

export { USER_SUBMITED_SDG_MAPPING, SUBMIT_DOCUMENT_FOR_SDG_MAPPING };


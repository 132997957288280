import React from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { SEARCH } from "../grapqhl/queries";
import SearchResultList from "../components/search/SearchResultItem";
import { Searching } from "../components/ProgressIndicators";

function Search() {
    const [searchParams] = useSearchParams();
    const searchTerms = searchParams.get('searchTerms')
    const searchType = searchParams.get('searchType');

    const navigate = useNavigate();

    const { data, loading, error } = useQuery(SEARCH, {
        variables: { searchTerms, searchType },
        skip: searchTerms === '',
    });

    if (searchTerms === '') {
        return <Navigate to='/' />;
    }

    if (loading) return <Searching />;

    if (error) return (
        <div className="container is-max-desktop">
            <section className="section">
                <div className="box level">
                    <p>Desculpe, não foi possível realizar a pesquisa.</p>
                    <button className="button is-primary" onClick={() => navigate(-1)}>Voltar</button>
                </div>
            </section>
        </div>
    );

    return (
        <div className="container is-max-desktop">
            <section className="section">
                <div className="box">
                    <div className="level">
                        <button className="button is-primary" onClick={() => navigate(-1)}>Voltar</button>
                        <div className="level-item ml-6">Termo pesquisado: {searchTerms}</div>
                        <div className="level-item ml-6">Resultados encontrados: {data.search.resultsCount}</div>
                    </div>
                </div>

                <SearchResultList results={data} />
            </section>
        </div>
    );
}

export default Search;

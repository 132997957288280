import React from 'react';
import { REPORT_DOCUMENT_COUNT } from '../../grapqhl/queries';
import { useQuery } from '@apollo/client';

function SearchForm() {
    const { data, loading, error } = useQuery(REPORT_DOCUMENT_COUNT);

    let normsCount = ""
    if (loading) {
        console.log("Fetching norms count")
    }

    if (error) {
        normsCount = ""
    };

    if (data) {
        const date = new Date(data.reportNormsCount.reportDate)
            .toLocaleString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })

        normsCount =
            data.reportNormsCount.count +
            " normas catalogadas, última atualização: " +
            date
    }

    return (
        <form action='/busca'>
            <fieldset>
                <div className='field is-grouped'>
                    <input className="input" name='searchTerms' placeholder='Pesquisa' autoFocus />
                    <button className="button is-primary">Pesquisar</button>
                </div>
                {/* <div>
                    <input className='radio' id='bySubject' type='radio' name="searchType" value="BY_SUBJECT" defaultChecked />
                    <label className='radio' htmlFor='subject'>Por assunto</label>

                    <input className='radio' id='byKeywords' type='radio' name="searchType" value="BY_KEYWORDS" />
                    <label className='radio' htmlFor='byKeywords'>Palavras-chave</label>

                    <input className='radio' id='byText' type='radio' name="searchType" value="FULL_TEXT" />
                    <label className='radio' htmlFor='byText'>Textual</label>
                </div> */}
            </fieldset>
            <p className='is-size-7'>{normsCount}</p>
        </form>
    );
}

export default SearchForm;

import React from "react";

function DocumentOverview(props) {
    const title = props.document.title;
    const source = props.document.source;
    const date = props.document.date;
    const abstract = props.document.abstract;
    const type = props.document.type;
    const externalURL = props.document.externalURL;
    const keywords = props.document.keywords;

    return (
        <div className="box">
            <table className="table is-narrow is-fullwidth is-size-7 is-striped">
                <thead>
                    <tr>
                        <td colSpan="2"><span className="has-text-weight-light is-size-4">Visão Geral</span></td>
                    </tr>
                </thead>
                <tbody>
                    <tr className="is-size-5">
                        <td className="has-text-right has-text-weight-bold">Título</td>
                        <td className="has-text-weight-bold">{title}</td>
                    </tr>
                    <tr>
                        <td className="has-text-right has-text-weight-bold">Origem</td>
                        <td>{source}</td>
                    </tr>
                    <tr>
                        <td className="has-text-right has-text-weight-bold">Data</td>
                        <td>{date}</td>
                    </tr>
                    <tr>
                        <td className="has-text-right has-text-weight-bold">Resumo</td>
                        <td>{abstract}</td>
                    </tr>
                    <tr>
                        <td className="has-text-right has-text-weight-bold">Tipo</td>
                        <td>{type}</td>
                    </tr>
                    <tr>
                        <td className="has-text-right has-text-weight-bold">Link externo</td>
                        <td><a target="_black" href={externalURL}>{externalURL}</a></td>
                    </tr>
                    <tr>
                        <td className="has-text-right has-text-weight-bold">Palavras-chave</td>
                        <td>{keywords}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default DocumentOverview;

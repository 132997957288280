import { useQuery } from "@apollo/client";
import React from "react";
import { GET_DOCUMENT_DETAILS } from "../../grapqhl/queries";
import { Loading } from "../ProgressIndicators";
import { Error500 } from "../../pages/errors_5xx";
import NormContents from "../document_details/NormContents";
import DocumentOverview from "./DocumentOverview";
import SdgRankings from "../document_details/SdgRankings";


function DocumentDetails({ documentId }) {
    const { data, loading, error } = useQuery(
        GET_DOCUMENT_DETAILS, {
        variables: { documentID: documentId },
    });
    if (loading) return <Loading />;
    if (error) return <Error500 />;

    const document = {
        title: data.getDocumentDetails.document.title,
        source: data.getDocumentDetails.document.source,
        date: data.getDocumentDetails.document.date,
        abstract: data.getDocumentDetails.document.abstract,
        type: data.getDocumentDetails.document.type,
        externalURL: data.getDocumentDetails.document.externalURL,
        keywords: data.getDocumentDetails.document.keywords,
        fullText: data.getDocumentDetails.document.fullText,
    }

    const sdgRankings = data.getDocumentDetails.sdgRankings;

    return (
        <section>
            <DocumentOverview document={document} />
            <div className="columns">
                <div className="column is-one-third">
                    <SdgRankings sdgRankings={sdgRankings} />
                </div>

                <div className="column">
                    <NormContents contents={document.fullText} />
                </div>
            </div>
        </section>
    );
}

export default DocumentDetails;

import React from 'react';
import { REPORT_SDG_RELEVANCE_COUNT } from '../../grapqhl/reports';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useQuery } from '@apollo/client';
import { makeRgb } from '../../helpers/helpers';
import { Loading } from '../ProgressIndicators';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function ReportSdgRelevanceCount() {
    const { data, loading, error } = useQuery(REPORT_SDG_RELEVANCE_COUNT);
    if (loading) return <Loading />
    if (error) return <p>Não foi possível exibir os dados</p>

    const reportDate =
        new Date(data.reportSdgRelevanceCount.reportDate)
            .toLocaleString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' })

    const chartData = {
        labels: createLabels(data.reportSdgRelevanceCount.entries),
        datasets: createDatasets(data.reportSdgRelevanceCount.entries),
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <div>
            <h3 className='subtitle'>
                Ocorrência de termos relacionados aos Objetivos do Desenvolvimento Sustentável nas normas<br />
                <span className='has-text-weight-normal is-size-6'>Atualizado em {reportDate}</span></h3>

            <div style={{ width: '700px' }} className='mx-auto'>
                <Bar options={options} data={chartData} />
            </div>
        </div>
    );
}

function createLabels(entries) {
    const labels = [];

    entries.forEach(entry => {
        labels.push("ODS " + entry.sdgGoal);
    })

    return labels;
}

function createDatasets(entries) {
    const sdgLabels = createLabels(entries);

    const dataset = [
        {
            label: "Quantitativo dos ODS",
            backgroundColor: makeRgb(),
            borderWidth: 2,
            data: Array(sdgLabels.length).fill(""),
        },
    ];

    entries.forEach(entry => {
        let label = "ODS " + entry.sdgGoal;
        const pos = sdgLabels.indexOf(label);
        dataset[0].data[pos] = entry.count;
    })

    return dataset;
}

export default ReportSdgRelevanceCount;

import { Fragment } from "react"

import ReportNormsByYear from "../reports/ReportNormsByYear";
import ReportNormsByYearAndState from "../reports/ReportNormsByYearAndState";
import ReportNormsCountByState from "../reports/ReportNormsCountByState";
import ReportNormsCountByType from "../reports/ReportNormsCountByType";
import ReportSdgCountRelevance from "../reports/ReportSdgRelevanceCount"

function AggregateData() {
    return (
        <Fragment>
            <div className="box">
                <div className="content mx-auto">
                    <h1 className="title">Dados agregados gerais</h1>
                    <p>
                        São apresentados aqui dados agregados gerais sobre as normas coletadas.
                        Conforme descrito na seção <em>Metodologia</em>, escolheu-se coletar somente as normas brasileiras publicadas
                        a partir do ano de 1988.
                    </p>
                    <section className="section"><ReportNormsByYear /></section>
                    <section className="section"><ReportNormsByYearAndState /></section>
                    <section className="section"><ReportNormsCountByState /></section>
                    <section className="section"><ReportNormsCountByType /></section>
                    <section className="section"><ReportSdgCountRelevance /></section>
                </div>
            </div>
        </Fragment >
    );
}

export default AggregateData;

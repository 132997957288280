import { Fragment } from "react"

function Overview() {
    return (
        <Fragment>
            <div className="box">
                <div className="content">
                    <h1 className="title">Apresentação</h1>
                    <p>
                        O <strong>NormaLIS</strong> é um sistema de coleta, indexação e análise de normas brasileiras - leis, decretos, resoluções, etc. - estaduais e federais
                        relacionadas ao tema <strong>Sustentabilidade</strong>.
                    </p>
                    <p>
                        A coleta (também conhecida como <em><a href="https://pt.wikipedia.org/wiki/Web_scraping" target="_blank" rel="noreferrer">web scrapping</a></em>) é
                        regularmente feita nos portais de pesquisa de cada um dos entes federativos, mantendo a base de dados constantemente atualizada.
                        O sistema permite busca nas normas coletadas e apresenta informações detalhadas sobre o relacionamento dessas normas
                        aos <strong><a href="https://brasil.un.org/pt-br/sdgs" target="_blank" rel="noreferrer">Objetivos do Desenvolvimento Sustentável&nbsp;(ODS)</a></strong>,
                        bem como estatísticas gerais sobre o tema.
                    </p>
                </div>
            </div>
        </Fragment >
    );
}

export default Overview;

import { gql } from "@apollo/client";


const REPORT_DOCUMENT_COUNT = gql`
query ReportNormsCount {
	reportNormsCount {
		count
		reportDate
	}
}`;

const SEARCH = gql`
query Search ($searchTerms: String!, $searchType: SEARCH_TYPE){
	search(searchTerms: $searchTerms, searchType: $searchType) {
		resultsCount
		results {
			abstract
			documentID
			externalURL			
			source
			title
			type
		}
	}
}
`;

const GET_DOCUMENT_DETAILS = gql`
query GetDocumentDetails($documentID: ID!) {
	getDocumentDetails(documentID: $documentID) {
		document {
			documentID
			title
			date
			abstract
			type
			keywords
			source
			externalURL
			fullText
		}
		sdgRankings {
			sdgGoal {
				score
				sdgGoal
				count
			}
			sdgTargets {
				score
				sdgTarget
				count
			}
		}
	}
}`;

export { REPORT_DOCUMENT_COUNT, SEARCH, GET_DOCUMENT_DETAILS };

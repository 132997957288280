import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/home";
import Search from "./pages/search_results";
import DocumentDetails from "./pages/document_details";
import { Error404 } from "./pages/error_4xx";

import './css/styles.css';
import SdgReport from "./pages/sdg_mapping";
import { SdgMapper } from "./pages/sdg_mapper_submission";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/busca" element={<Search />} />
          <Route path="/detalhes/:id" element={<DocumentDetails />} />
          <Route path="/relatorio_ods/" element={<SdgReport />} />
          <Route path="/mapeador_ods/" element={<SdgMapper />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

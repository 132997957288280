function Methodology() {
    return (
        <article className="box content">
            <h1 className="title">Metodologia</h1>
            <p>
                O NormaLIS, de maneira automática e periódica, acessa <em>mecanismos de pesquisa</em> de normas de todos os Estados, Distrito Federal
                e Federação, realiza uma busca por termos específicos (ver mais abaixo) e coleta as normas (metadados + texto completo) listadas nos resultados.
            </p>
            <p>
                <strong>Decidiu-se coletar somente normas desde o ano de 1988.</strong>
            </p>
            <p>
                Na maioria dos casos, esse mecanismo é um portal de buscas convencional, em que o usuário digita
                alguns termos e realiza a recuperação dos documentos.
                Um limitado número de portais oferece o acesso às informações por meio de API oficial. Estão listados abaixo três exemplos:</p>
            <ul>
                <li><a href="https://www.lexml.gov.br/" target="_blank" rel="noreferrer">Rede de Informação Legislativa e Jurídica</a> (LexML)</li>
                <li><a href="https://normas.leg.br/" target="_blank" rel="noreferrer"><strong>Normas</strong>.leg.br</a></li>
                <li><a href="https://dadosabertos.almg.gov.br/ws/ajuda/sobre">Dados Abertos da Assembleia Legislativa de Minas Gerais</a></li>
            </ul>
            <p>
                Há, ainda, um número menor de casos em que a API foi descoberta mediante a análise do código-fonte das páginas. Um exemplo disso é o
                do Estado de Goiás, que fornece um <a href="https://legisla.casacivil.go.gov.br" target="_blank" rel="noreferrer">portal de buscas</a> convencional
                e que obtêm seus dados de uma API, disponível <a href="https://legisla.casacivil.go.gov.br/api/v2/pesquisa/legislacoes">aqui</a>.
            </p>
            <p>
                Devido à maior facilidade de interação, sempre que possível foram utilizadas APIs para a busca de normas.
            </p>

            <h3 className="subtitle">Termos de pesquisa</h3>
            <p>
                A recuperação de normas emprega a busca mediante pesquisa de três termos: <em>Sustentabilidade, Sustentável, Sustentáveis</em>.
                Em cada portal de pesquisa foi necessário estabelecer se a busca seria feita
                por <strong>assunto</strong>, <strong>busca textual</strong>, <strong>ementa</strong> ou alguma combinação dessas opções.
                Isso se deu pois foi observado, ao longo do desenvolvimento do sistema, que cada portal retornava resultados mais ou menos satisfatórios
                de acordo com o tipo de busca.
            </p>

            <h3 className="subtitle">Metadados</h3>
            <p>
                Depois de realizada a pesquisa, os metadados - <em>título, data, ementa (resumo), tipo de norma, situação da norma, palavras-chave</em> - e o texto completo
                de cada norma são coletados.
                Somente as normas cujo texto completo pôde ser recuperado são efetivamente inseridas no NormaLIS, já que parte fundamental do sistema
                é justamente a análise do conteúdo
            </p>
            <p>
                Há normas em que alguns dos metadados não podem ser obtidos de maneira assertiva. Esse problema ocorre em especial no caso das palavras-chave, que nem
                sempre estão disponíveis, e a informação se uma determinada norma está ou não vigente.
            </p>
            <p>
                No caso das palavras-chave, a norma é coletada sem essa informação. Seria possível produzir uma lista de palavras-chave de maneira automática,
                mas decidiu-se pela não realização dessa ação.
            </p>
            <p>
                Em relação à situação da norma, internamente o NormaLIS emprega 4 casos possíveis:</p>
            <ol>
                <li>
                    <strong>Ativa e Relevante</strong>: normas vigentes e relacionadas aos termos de busca
                </li>
                <li>
                    <strong>Inativa e Relevante</strong>: norma não-vigente mas relacionada aos termos de busca
                </li>
                <li>
                    <strong>Ativa e Irrelevante</strong>: norma vigente e que, por algum critério do NormaLIS, foi considerada irrelevante.
                </li>
                <li><strong>Inativa e Irrelevante</strong>: norma não-vigente e que, por algum critério do NormaLIS, foi considerada irrelevante</li>
            </ol>
            <p>
                As situações (3) e (4) não são relevantes para a pesquisa sobre normas, mas são necessárias por requisitos internos do próprio sistema.
            </p>
            <p>
                Nos casos em que a situação da norma não pode ser definida de maneira assertiva, escolheu-se atribuir a situação 1 (Ativa e Relevante),
                partindo-se do princípio que, <em>se a norma apareceu nos resultados de busca, ela é relevante e provavelmente ainda está vigente</em>.
            </p>

            <h4 className="subtitle">Tipos de normas</h4>
            <p>
                Os tipos de normas disponibilizados nos diversos portais de pesquisa coletados apresentam certo grau de heterogeneidade,
                tanto na nomenclatura quanto na variedade.
            </p>
            <p>
                Durante o desenvolvimento do NormaLIS, o tipo das normas era registrado conforme obtido na fonte, apresentando grande variedade de
                nomes. Finalizada a coleta de todos os portais, realizou-se a normalização desses tipos. O quadro abaixo exemplifica alguns dos termos
                originais e que foram normalizados para o tipo <strong>Decreto</strong>:
            </p>

            <div class="message mx-auto">
                <div class="message-body">
                    <em>DEC, Decreto Orçamentário, Decreto Suplementar,
                        Decreto Regulamentar, DECRETO, DECRETO "E",
                        Decreto com numeração especial, Decreto Numerado,
                        Decreto Legislativo, Decreto do Executivo,
                        Decreto Normativo, Decreto sem Número</em>
                </div>
            </div>

            <p>
                Para o tipo <strong>Decreto</strong>, foi feita a normalização excepcional do subtipo <em>Decreto Financeiro</em>,
                devido ao expressivo número de normas publicadas por esse instrumento, ainda que empregado somente pelo Estado da Bahia.
            </p>
            <p>
                A normalização dos tipos seguiu o <a href="https://www.congressonacional.leg.br/legislacao-e-publicacoes/glossario-tecnica-legislativa" target="_blank" rel="noreferrer">
                    <em>Glossário de Termos da Técnica Legislativa</em>
                </a> disponibilizado pelo Congresso Nacional.
            </p>
            <p>
                A tabela a seguir apresenta a normalização atualmente empregada pelo NormaLIS.
            </p>

            <table className="table is-striped is-narrow mx-auto">
                <thead className="has-text-centered">
                    <tr>
                        <th>Termo original</th>
                        <th>Termo normalizado</th>
                        <th>Observações</th>
                    </tr>
                    <tr>
                        <th className="is-size-7 has-text-weight-normal" colSpan="3">Por ordem alfabética do <em>termo normalizado</em></th>
                    </tr>
                </thead>
                <tbody className="is-size-7">
                    <tr>
                        <td>
                            Ato Administrativo Parlamentar,
                            Ato Administrativo,
                            Ato da Mesa,
                            Ato do Presidente
                        </td>
                        <td>
                            Ato Normativo
                        </td>
                        <td>
                            <p>Aplicou-se o conceito amplo de <strong>Ato</strong>, conforme o <em>Glossário de Termos da Técnica Jurídica</em>:</p>
                            <p><cite><strong>Ato (Norma Jurídica)</strong>: Norma interna proferida por autoridade ou órgão colegiado competente.</cite></p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            Constituição Estadual, Constituição (estadual)
                        </td>
                        <td>
                            Constituição
                        </td>
                        <td> </td>
                    </tr>

                    <tr>
                        <td>
                            DEC, Decreto Orçamentário, Decreto Suplementar,
                            Decreto Regulamentar, DECRETO, DECRETO "E",
                            Decreto, Decreto com numeração especial, Decreto Numerado,
                            Decreto Legislativo, Decreto do Executivo, Decreto Normativo,
                            Decreto sem Número, Decretos Numerados, DE - Decreto Legislativo,
                            Decreto Estadual
                        </td>
                        <td>
                            Decreto
                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>
                            Decretos Financeiros
                        </td>
                        <td>
                            Decreto Financeiro
                        </td>
                        <td>
                            Instrumento usado somente pelo Estado da Bahia
                        </td>
                    </tr>

                    <tr>
                        <td>
                            DELIBERAÇÃO CA-FUNDERSUL
                        </td>
                        <td>
                            Deliberação
                        </td>
                        <td> </td>
                    </tr>

                    <tr>
                        <td>
                            Emenda Constitucional, EMENDA CONSTITUCIONAL,
                            EC - Emenda Constitucional, Emenda à Constituição,
                            EMC
                        </td>
                        <td>
                            Emenda Constitucional
                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        <td> INT </td>
                        <td> Instrução Normativa </td>
                        <td> </td>
                    </tr>

                    <tr>
                        <td>
                            Indicação Legislativa
                        </td>
                        <td>
                            Indicação Legislativa
                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>
                            LEI COMPLEMENTAR, Leis Complementares,
                            Lei Complementar, LC - Lei Complementar, LCP
                        </td>
                        <td>Lei Complementar</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>
                            Lei Delegada, LEI DELEGADA
                        </td>
                        <td>Lei Delegada</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>
                            LEI ORDINARIA, Lei Ordinária, LEI - Lei Ordinária, Leis Ordinárias,
                            LEI, Lei, LOP - Lei Ordinária Promulgada, Lei Promulgada
                        </td>
                        <td>Lei Ordinária</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>
                            Medida Provisória
                        </td>
                        <td>
                            Medida Provisória
                        </td>
                        <td> </td>
                    </tr>

                    <tr>
                        <td>
                            Ordem de Serviço do Governador, Diversos,
                            MENSAGEM GOV/MS, MENSAGEM GABGOV, MENSAGEM GABGOV/MS
                        </td>
                        <td>
                            Outros
                        </td>
                        <td> </td>
                    </tr>

                    <tr>
                        <td>
                            Portaria, Portaria Orçamentária, PORTARIA
                        </td>
                        <td>
                            Portaria
                        </td>
                        <td> </td>
                    </tr>

                    <tr>
                        <td>Regimento Interno - Resolução 08/2023
                        </td>
                        <td>
                            Regimento Interno
                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>
                            Resolução Legislativa, Resolução,
                            RE - Resolução, RESOLUÇÃO CONJUNTA SEGOV/SAD,
                            Resolução da Alepe, Resolução da Alesp,
                            RESOLUÇÃO SEGOV/MS
                        </td>
                        <td>
                            Resolução
                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>
                            <em>Tipificação automática para eventuais normas não mapeadas durante o desenvolvimento do NormaLIS</em>
                        </td>
                        <td colSpan="2">
                            <em>[termo original] - (tipo não normalizado)</em>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan="3" className="is-size-7 has-text-weight-light">
                            <p>Observação geral: a coluna <em>Termo original</em> apresenta o termo conforme obtido de cada portal de pesquisa.</p>
                            <p>Para fins de armazenamento em banco de dados e processamento via software, caracteres maiúsculos e minúsculos são interpretados de
                                forma diferente; <em>Decreto</em> e <em>DECRETO</em> são considerados "valores" distintos nesse contexto.
                            </p>
                        </th>
                    </tr>
                </tfoot>
            </table>

            <p>
                Por fim, ao termo normalizado é adicionado um sulfixo de acordo com a esfera de poder em que a
                norma foi produzida - <em>Distrital, Estadual</em> ou <em>Federal</em>.
            </p>

            <p>
                O NormaLIS foi desenvolvido prevendo a possibilidade de coleta de normas municipais. Todavia, devido ao imenso trabalho
                necessário para implementar tal recurso, optou-se por não realizá-lo.
            </p>

            <h3 className="subtitle">Indexação do conteúdo das normas</h3>
            <p>
                Para que a pesquisa pelas normas coletadas possa ser feita, o texto completo dos
                documentos é <a target="_blank" rel="noreferrer" href="https://brasileiraspln.com/livro-pln/1a-edicao/parte3/cap4/cap4.html#sec-cap4-sec4.1.2"><em>tokenizado</em></a> empregando-se um dicionário-padrão da língua portuguesa.
            </p>
            <p>
                Idealmente, seria mais adequado o uso de um dicionário de tokenização especializado, construído especificamente para o escopo deste projeto.
                O tokenizador empregado pelo NormaLIS faz parte do <em>Sistema Gerenciador de Banco de Dados PostgreSQL</em>, e nenhuma modificação
                foi feita ao dicionário.
            </p>
            <p>
                Como exemplo da limitação do dicionário utilizado, a tabela abaixo lista o token gerado para
                alguns termos:
            </p>

            <div className="columns">
                <div className="column"></div>
                <div className="column">
                    <table className="table is-narrow is-bordered">
                        <thead>
                            <tr className="has-text-centered">
                                <th>Termo</th>
                                <th>Token equivalente</th>
                            </tr>
                        </thead>
                        <tbody className="has-text-right is-size-6">
                            <tr>
                                <td>Sustentável</td>
                                <td className="has-text-centered" rowSpan="5"><span className="is-family-code">'sustent'</span></td>
                            </tr>
                            <tr><td>Sustentabilidade</td></tr>
                            <tr><td>Sustentáveis</td></tr>
                            <tr><td><strong>sustento</strong></td></tr>
                            <tr><td>sustentável</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="column"></div>
            </div>

            <p>
                Como se vê, a tokenização do termo <em>sustento</em> o torna equivalente aos demais termos. Adicionalmente,
                a capitalização da primeira letra dos termos também não gera tokens diferenciados, o que poderia ser explorado
                para melhorar a qualidade dos resultados.
            </p>
        </article>
    );
}

export default Methodology;

import { gql } from "@apollo/client";

const QUERY_NORMS = gql`
query Norms($count: Int, $afterCursor: ID, $beforeCursor: ID, $filter: NormsFilterInput) {
	norms(count: $count, afterCursor: $afterCursor, beforeCursor: $beforeCursor, filter: $filter) {
		pageInfo {
			nextPageCursor
			pageNodesCount
		}

		edges {
			node {
				documentID
				title
				type
				abstract
				location
				externalURL
				date
			}
			cursor
		}
	}
}`;

const LOCATIONS = gql`
query Locations {
	locations {
		locations {
			id
			name
			abbreviation
			parentId			
		}
	}
}`;

const DOCUMENT_TYPES = gql`
query DocumentTypes {
	documentTypes {
		documentTypes {
			id
			name
			authority
			isPrivate
		}
	}
}`;

export { LOCATIONS, DOCUMENT_TYPES, QUERY_NORMS }

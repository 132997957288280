import React from 'react';
import NormSourceSummary from '../components/research_results/NormsSourceSummary';
import { useState } from 'react';
import Overview from '../components/research_results/Overview';
import Methodology from '../components/research_results/Methodology';
import AggregateData from '../components/research_results/AggregateData';
import SdgRanking from '../components/research_results/SdgRanking';

const stateNames = new Map();

stateNames.set("BR", "Brasil");
stateNames.set("AC", "Acre");
stateNames.set("AL", "Alagoas");
stateNames.set("AP", "Amapá");
stateNames.set("AM", "Amazonas");
stateNames.set("BA", "Bahia");
stateNames.set("CE", "Ceará");
stateNames.set("ES", "Espírito Santo");
stateNames.set("GO", "Goiás");
stateNames.set("MA", "Maranhão");
stateNames.set("MT", "Mato Grosso");
stateNames.set("MS", "Mato Grosso do Sul");
stateNames.set("MG", "Minas Gerais");
stateNames.set("PA", "Pará");
stateNames.set("PB", "Paraíba");
stateNames.set("PR", "Paraná");
stateNames.set("PE", "Pernambuco");
stateNames.set("PI", "Piauí");
stateNames.set("RJ", "Rio de Janeiro");
stateNames.set("RN", "Rio Grande do Norte");
stateNames.set("RS", "Rio Grande do Sul");
stateNames.set("RO", "Rondônia");
stateNames.set("RR", "Roraima");
stateNames.set("SC", "Santa Catarina");
stateNames.set("SP", "São Paulo");
stateNames.set("SE", "Sergipe");
stateNames.set("TO", "Tocantins");
stateNames.set("DF", "Distrito Federal");



function NormSourcesSummaries() {
    const [currentPage, setCurrentFederativeEntity] = useState("overview")
    function handleCurrentPage(currentFederativeEntity) {
        setCurrentFederativeEntity(currentFederativeEntity)
    }

    const pagesList = [
        "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES",
        "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR",
        "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC",
        "SP", "SE", "TO",
        "overview", "methodology", "aggregateData", "sdgRanking"
    ]

    return (
        <div className="container">
            <section className="section">
                <h1 className="title">Apresentação e Metodologia</h1>
                <h2 className="subtitle">Coleta automática de normas e sua análise</h2>
            </section>

            <section className="section">
                <div className="columns">
                    <div className="column is-one-quarter">
                        <aside className="menu box">
                            <p className="menu-label">Geral</p>
                            <ul className="menu-list">
                                <li key="overview">
                                    <a href="#" className={`${currentPage === "overview" ? 'is-active' : ''}`} onClick={() => handleCurrentPage("overview")}>Apresentação</a>
                                </li>
                                <li key="methodology">
                                    <a href="#" className={`${currentPage === "methodology" ? 'is-active' : ''}`} onClick={() => handleCurrentPage("methodology")}>Metodologia</a>
                                </li>
                                <li key="sdgRanking">
                                    <a href="#" className={`${currentPage === "sdgRanking" ? 'is-active' : ''}`} onClick={() => handleCurrentPage("sdgRanking")}>Objetivos do Desenvolvimento Sustentável</a>
                                </li>
                                <li key="aggregateData">
                                    <a href="#" className={`${currentPage === "aggregateData" ? 'is-active' : ''}`} onClick={() => handleCurrentPage("aggregateData")}>Dados agregados gerais</a>
                                </li>
                            </ul>

                            <p className="menu-label">Normas Federais</p>
                            <ul className="menu-list">
                                <li key="BR"><a href="#" className={`${currentPage === "BR" ? 'is-active' : ''}`} onClick={() => handleCurrentPage("BR")}>Brasil</a></li>
                            </ul>

                            <p className="menu-label">Normas Estaduais e Distrito Federal</p>
                            <ul className="menu-list">
                                {pagesList.map((item) =>
                                    <li key={item}><a href="#" className={`${item === currentPage ? 'is-active' : ''}`} onClick={() => handleCurrentPage(item)}>{stateNames.get(item)}</a></li>
                                )}
                            </ul>
                        </aside>
                    </div>

                    <div className="column">
                        <SetPageContents pageName={currentPage} />
                    </div>
                </div>
            </section>
        </div >
    );
}

function SetPageContents(props) {
    const pageName = props.pageName

    switch (pageName) {
        case "overview":
            return <Overview />;
        case "methodology":
            return <Methodology />;
        case "aggregateData":
            return <AggregateData />;
        case "sdgRanking":
            return <SdgRanking />
        default:
            return <NormSourceSummary data={pageName} />
    }
}

export { NormSourcesSummaries, stateNames };

import React from 'react';
import { REPORT_NORMS_COUNT_BY_TYPE } from '../../grapqhl/reports';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useQuery } from '@apollo/client';
import { Loading } from '../ProgressIndicators';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function ReportNormsByType() {
    const { data, loading, error } = useQuery(REPORT_NORMS_COUNT_BY_TYPE);
    if (loading) return <Loading />
    if (error) return <p>Não foi possível exibir os dados</p>

    const reportDate =
        new Date(data.reportNormsCountByType.lastUpdate)
            .toLocaleString('pt-br', { year: 'numeric', month: 'long', day: 'numeric' });

    return (
        <article>
            <h3 className='subtitle'>
                Quantitativo de normas Federais, Estaduais e Distrito Federal por tipo<br />
                <span className='has-text-weight-normal is-size-6'>Atualizado em {reportDate}</span></h3>
            <p>
                Os tipos de normas disponibilizados nos diversos portais de pesquisa coletados apresentam certo grau de heterogeneidade,
                tanto na nomenclatura quanto na variedade. A tabela a seguir apresenta a participação percentual de cada tipo de
                acordo com a normalização descrita na seção <em>Metodologia</em>.
            </p>
            <div style={{ width: '700px' }} className='mx-auto'>
                <DataTable data={data.reportNormsCountByType} lastUpdate={reportDate} />
            </div>
        </article>
    );
}

function DataTable(props) {
    const tableData = props.data.entries;

    let totalNormsCount = 0;
    tableData.forEach(element => {
        totalNormsCount = totalNormsCount + element.count;
    });

    return (
        <div className='mx-auto'>
            <table className='table is-striped is-hoverable is-narrow'>
                <thead>
                    <tr>
                        <th colSpan="3" className='is-size-7 has-text-centered has-text-weight-normal'>Inclui normas vigentes e não vigentes</th>
                    </tr>
                    <tr className='is-size-6'>
                        <th className='has-text-centered'>Tipo de Norma</th>
                        <th className='has-text-centered'>Quantitativo</th>
                        <th className='has-text-centered'>Percentual</th>
                    </tr>

                </thead>
                <tbody className='is-size-7'>
                    {
                        tableData.map(
                            (item) => <tr key={item.documentType}>
                                <td>{item.documentType}</td>
                                <td className='has-text-centered'>{item.count}</td>
                                <td className='has-text-centered'>{(item.count / totalNormsCount * 100).toFixed(2)}%</td>
                            </tr>
                        )
                    }
                </tbody>
                <tfoot>
                    <tr className='is-size-6'>
                        <th className='has-text-right'>Total</th>
                        <th className='has-text-centered'>{totalNormsCount}</th>
                        <th className='has-text-centered'>100%</th>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
}

export default ReportNormsByType;
